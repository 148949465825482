.svglogo{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  margin-top: 80px;
}


/* Navbars */
.navbar-translate {
  font-size: 1.3em;
}

.navbar .navbar-nav {
  font-size: 1.3em;
}

.language-flags {
  margin-left: 30px;
  margin-top: 5px;
}

/* BlankHeader */
.blank-header {
  min-height: 100vh;
  max-height: 440px;
  background: white;
}

/* LandingPage */
.content-center{
  font-size: 1.3em;
}

.landing-page .section-team .team .team-player img {
  max-width: 300px;
}

.fa-angle-double-down{
  background-color: transparent;
  color: white;
  font-size: 3em;
  margin-top: 16em;
  margin-left: 50%;
}

.description,
.card-description,
.footer-big p {
  color: #636161;
  font-weight: 300;
}

.btn-outline-primary {
    color: #2CA8FF;
    border-color: #2CA8FF;
}

.blockquote {
  border-left: none;
  border: 1px solid #888888;
  padding: 20px;
  font-size: 1.5em;
  line-height: 1.5;
  border-color: #ae8f00 !important;
  color: #ae8f00 !important;
  position: relative;
  margin-right: 50px;
}

.blockquote.blockquote-info small {
  color: #ae8f00 !important;
}

.section-story-overview .image-container:nth-child(2) {
  margin-top: 270px;
  margin-left: -105px;
  height: 420px;
}

.bg-info {
  background-color: #ae8f00 !important;
}

.btn-outline-primary {
  background-color: #ae8f00;
  border-color: #ae8f00;
  color: white;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  padding-top: 0rem;
}

.section {
  padding: 30px 0;
}

.page-header.page-header-small {
  min-height: 100vh;
  max-height: 440px;
}

.landing-page .link {
  font-size: 10px;
  color: #2CA8FF;
  text-decoration: none;
}


/*===Bees Page===*/
.scale-data-iot {
  width: 1000px;
  height: 500px;
  position: relative;
  top: 0px;
  border: 0px ;
}

.videocontainer{
  position:relative;
  float:left;
}

.overlay{
  top:0;
  left:0;
  width:100%;
  height:100%;
  position:absolute;}

.video-wrapper {
  position: relative;
}

.title-webcam {
  font-size: 1.5em;
  line-height: 1em;
  font-weight: 700;
  text-align: center;
  margin: 0px;
}

.btn-scale {
  font-weight: 700;
  font-size: 1em;
  padding: 10px 20px !important;
  margin-bottom: 4  0px;
}

.btn-livecam {
  font-size: 1em;
  margin-top: 5px;
  padding: 5px 20px !important;
}

.livecam {
  width: 1024px;
  margin-bottom: 15px;
}

.raspicam {
  width: 1100px;
  height: 800px;

}

.text-info {
    color:  #ae8f00 !important;
}

/*======Shop styling======*/
.shoppage-header {
  min-height: 60vh !important;
  max-height: 250px !important;
}

h3 {
  text-align: center;
  padding: 5%;
  font-size: 2rem;
  font-weight: 700;
}

h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.box {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.material-icons {
  font-size: 2.4em;
  cursor: pointer;
}

.card {
  width: 500px;
}

.card .card-image .card-title {
  color: black;
  font-weight: 400;
}

.card-image{
    padding: 5%
}
.card-title {
    margin-bottom: .2rem;
    line-height: 1em !important;
    font-weight: 700;
    font-size: 2em;
    line-height: 3;
}

.card-origin {
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.card-story {
    margin-top: 0rem;
    margin-bottom: .5rem;
}

.cart {
  margin-top: 40px;
}

.collection .collection-item {
  display: flex;
}

.collection .collection-item img {
  width: 150px;
  margin-right: 25px;
}

.collection{
  padding: 0;
}

.button-container{
  text-align: center;
}

.card-broad{
  min-width: 45%;
  padding:0;
  padding-left: 20px;
  text-align: left;
}
.card-narrow{
  min-width: 9%;
  max-width: 9%;
  padding: 0;
  text-align: center;
  font-size: .8em;
}

.card-middle{
  min-width: 15%;
  max-width: 15%;
  padding: 0;
  padding-left: 10px;
  text-align: left;
}

.shop-btn {
  color: #fff !important;
  background: #ae8f0c;
  padding: 10px;
  border-radius: 30px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
  margin-top: 2%;
  margin-bottom: 2%;
  width: 70px;
}

.btn-out{
  padding: 10px;
  margin-top: 2%;
  margin-bottom: 2%;
}

.shop-btn:hover {
  color: black !important;
  background: #ae8f00;
  letter-spacing: 1px;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
  transition: all 0.4s ease 0s;
}

.shop-btn:active {
  background: red;
}

table {
  width: 100%;
  margin: 20px auto;
  table-layout: auto;
}

td {
  font-size: 1.1em;
  padding: 10px;
}

th {
  font-size: 1.1em;
  font-weight: 700;
  padding: 10px;
}

.fixed {
  table-layout: fixed;
}
.narrow {
   width: 30%;
   text-align: left;
 }

 .col {
   flex-grow: 0;
 }

.card-content {
  text-align: center;
}

.card-story {
  font-weight: 400;
  text-align: center;
  padding: 2%;
}

b {
  font-size: 1rem;
}

.checkout-btn {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #ae8f0c;
  padding: 10px;
  border-radius: 30px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
}
.delete {
  padding: 5px;
  width: 30px
}

.checkout-btn:hover {
  color: black !important;
  background: #ae8f00;
  letter-spacing: 1px;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
  transition: all 0.4s ease 0s;
  text-align: center;
}

.checkout-btn:active {
  background: red;
}

.checkout-products {
  text-align: left;
}

.shop-product-title {
  font-weight: bold;
  margin-bottom: 5%;
  font-size: 1.2em;
}

input {
  text-align: left !important;
}



/*===Shop order form===*/
.container-form {
  text-align: center;
}

.container-form h1 {
  font-size: 40px;
  font-weight: 200;
}


input, textarea {
  border: 0.5px solid black;
  background-color: #fffdf6;
  width: 250px;
  border-radius: 3px;
  font-family: "Source Sans Pro", sans-serif;
  padding: 10px 15px;
  margin: 0 auto 10px auto;
  display: block;
  text-align: center;
  font-size: 18px;
  color: black;
  font-weight: 300;
}

.cart-logo {
  right: 20%;
  position: relative;
}

.cart-award-logo {
  position: relative;
}

.cart {
  background: #fff;
  box-shadow: 0 2px 5px #ccc;
  padding: 3%;
}

.copyright{
  float: none !important;
  text-align: center !important;
}

.gallery-serator-top{
  margin-top: 60px;

}

.gallery-section-picture-tenuta{
  height: 640px;
  background-size: 1777px;
}

.gallery-section-picture-dachlissen{
  height: 525px;
  background-size: 1777px;
}

.gallery-section-picture-lema{
  height: 610px;
  background-size: 1777px;
}

.gallery-section-title{
  text-align: center;
  padding-top: 3em;
  color: white;
  font-weight: 700;
  font-size: 5em;
}

.title-youtube{
  padding-top: 0px;
  margin-top: 0px;
}

.recaptcha-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scale-iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px; /* Set the maximum width of the container */
  overflow: hidden;
  padding-bottom: 50%; /* Maintain a 2:1 aspect ratio */
  position: relative;
}

.scale-data-iot {
  border: none;
  display: block;
  height: 100%; /* Ensure the iframe takes up the full height of the container */
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; /* Ensure the iframe takes up the full width of the container */
}

.profile-page h5.description {
  max-width: 700px;
  margin: 0px auto 20px; }

.date.webcam {
  margin-bottom: 0px;
}
